import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSuppliers(ctx, queryParams) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .get(`/jobs/suppliers/`, {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params)
              }
            )
            .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => {
              store.commit('app/LOADING', false)
              reject(error)
            })
        })
    },
    fetchSupplierTypes(ctx, queryParams) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .get(`/jobs/supplier_types/`, {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params)
              }
            )
            .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => {
              store.commit('app/LOADING', false)
              reject(error)})
        })
    },
    updateSupplier(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/suppliers/${queryParams.id}/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    addSupplier(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/jobs/suppliers/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    fetchSupplierContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vision/supplier_contact/`, {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            }
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateSupplierContact(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vision/supplier_contact/${queryParams.id}/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    addSupplierContact(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/vision/supplier_contact/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
  }
}