import store from '@/store'
import axios from '@axios'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProjects(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/projects/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params) 
        })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    fetchProject(ctx, queryParams ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/jobs/projects/${queryParams.id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    addProject(ctx, data) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/jobs/projects/', { 
            ...data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    updateProject(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/projects/${queryParams.id}/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },

    addMarket(ctx, {id, data}) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/jobs/projects/${id}/field_countries/`, { 
            ...data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    fetchProjectsStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/project_status_summary/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params) 
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/min/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/users/departments/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchGroups(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/users/teamsgroups/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchOffices(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/offices/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/clients/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  },
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/categories/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBusinessInit(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/business_initiatives/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/countries/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchModules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/modules/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/project_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResearchLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/research_locations/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResearchMethods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/research_methods/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/service_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBillingRules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/billing_rules/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/currencies/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchShelfTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/shelf_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStudioRateCards(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/studio_rate_cards/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQualSampleType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/qual_sample_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/statuses/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFieldLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/field_locations/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/tasks/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProjectTask(ctx,  { task }  ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/planners/projects_tasks/', task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    updateProjectTask(ctx, { task } ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/planners/projects_tasks/${task.id}/`, task )
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    removeProjectTask(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/planners/projects_tasks/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    fetchCSATSurvey(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/surveys/csat/${queryParams.id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCSATResult(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/surveys/csat/${queryParams.id}/results/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCSATResult(ctx, data) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/surveys/csat_results/`, {
            ...data
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error =>  {
            store.commit('app/LOADING', false)
            reject(error)}
            )
      })
    },

    // Norms
    fetchRetailChannels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/retail_channels/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSampleTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/sample_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBrandTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/brand_types/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClientObjs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/client_objs/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPackChange(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/pack_changes/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadProjects(ctx, queryParams){
      store.commit('app/LOADING', true)

      return new Promise((resolve, reject) => {
          axios
          .get(`/jobs/report/xlsx`,{
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              responseType: 'blob',
              headers: {
                  'Authorization': localStorage.getItem('accessToken'),
                  'Content-Type': 'application/vnd.ms-excel;charset=UTF-8'
                  
              }
            })
          .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
          })
          .catch(error => {
              reject(error)
          })
      
      })
  },
  downloadTimeline(ctx, data ) {
    
    return new Promise((resolve, reject) => {
        axios
        .patch(`/planners/projects/${data.job_number}/xlsx/`, data, { responseType:'blob'})
        .then(response => {
            resolve(response)
        })
        .catch(error => reject(error))
    })
},
    // fetchIntercoWorkTypes(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/vision/work_types/', {
    //           params: queryParams,
    //           paramsSerializer: params => transformRequestOptions(params),
    //           useCache: true
    //         })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchIntercos(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/vision/intercompanies/', {
    //           params: queryParams,
    //           paramsSerializer: params => transformRequestOptions(params),
    //           useCache: true
    //         })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // addInterco(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/vision/intercompanies/', data)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // updateInterco(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .patch(`/vision/intercompanies/${data.id}/`, {
    //           ...data
    //         })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // deleteInterco(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete(`/vision/intercompanies/${data.id}/`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
  
}
