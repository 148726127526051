<template>
    <!-- edit profile dialog -->
    <v-dialog
      v-model="isSupplierContactDialogOpen"
      max-width="650px"
      @click:outside="$emit('update:is-supplier-contact-dialog-open',false)"
    >
      <v-card class="project-edit-info pa-sm-10 pa-3">
        <v-card-title class="justify-center text-h5">
          Edit Supplier Contact Info
        </v-card-title>
  
        <v-card-text class="mt-5">
                <v-form
                    ref="refSupplierContactEventHandlerForm"
                    class="px-5 pt-3 pb-3"
                    @submit.prevent="handleFormSubmit"
                >
                    <v-text-field
                        v-model="supplierContactLocal.first_name"
                        label="First Name"
                        placeholder="John"
                        outlined
                        :rules="[validators.required]"
                        hide-details="auto"
                        dense
                        class="mb-6"
                    ></v-text-field>
                    <v-text-field
                        v-model="supplierContactLocal.last_name"
                        label="Last Name"
                        placeholder="Smith"
                        outlined
                        :rules="[validators.required]"
                        hide-details="auto"
                        dense
                        class="mb-6"
                    ></v-text-field>

                    <v-text-field
                        v-model="supplierContactLocal.email"
                        label="Email"
                        placeholder="john.smith@smith.com"
                        outlined
                        hide-details="auto"
                        dense
                        class="mb-6"
                        :rules="[validators.required, validators.emailValidator]"
                    ></v-text-field>

                    <v-col
                    cols="12"
                    class="d-flex justify-center mt-3"
                    >
                    <v-btn
                        color="primary"
                        class="me-3"
                        type="submit"
                    >
                        {{ supplierContactLocal.id ? 'Update' : 'Add ' }}
                    </v-btn>
                    <v-btn
                        outlined
                        @click="resetEventLocal"
                    >
                        Reset
                    </v-btn>
                    </v-col>
                </v-form>
                
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
  
<script lang="js">
import { ref, watch, } from '@vue/composition-api'

import { required, emailValidator } from '@core/utils/validation'
import { mdiTrashCanOutline, mdiClose, mdiPlus } from '@mdi/js'
import useSupplierList from '../supplier-list/useSupplierList'
  
  export default {
    props: {
        isSupplierContactDialogOpen: {
            type: Boolean,
            required: true,
        },
        supplierContact: {
            type: Object,
            required: true,
        },
    },
  
    setup(props, { emit }) {
  
        // ————————————————————————————————————
        //* ——— Template Refs
        // ————————————————————————————————————

        const refSupplierContactEventHandlerForm = ref(null)

        // ————————————————————————————————————
        //* ——— Local Event
        // ————————————————————————————————————

        const supplierContactLocal = ref(JSON.parse(JSON.stringify(props.supplierContact)))
        const resetEventLocal = () => {
        supplierContactLocal.value = JSON.parse(JSON.stringify(props.supplierContact))
        

        // Reset Validation
        if(refSupplierContactEventHandlerForm.value) refSupplierContactEventHandlerForm.value.resetValidation()
        }



        const {

        } = useSupplierList()

        watch(
        () => props.supplierContact,
        () => {
            resetEventLocal()
        },
        { deep: true },
        )


        // ————————————————————————————————————
        //* ——— Form
        // ————————————————————————————————————

        const handleFormSubmit = () => {
        const isFormValid = refSupplierContactEventHandlerForm.value.validate()

        if (!isFormValid) return

        const supplierContactData = JSON.parse(JSON.stringify(supplierContactLocal.value))

        // * If event has id => Edit Event
        // Emit event for add/update event
        if (supplierContactData.id) emit('update-contact', supplierContactData)
        else emit('add-contact', supplierContactData)

        // Close sidebar
        emit('close-dialog')
        }

        return {
        

        // Template Refs
        refSupplierContactEventHandlerForm,

        // Local Event
        supplierContactLocal,
        resetEventLocal,

        // Form
        handleFormSubmit,

        // Field Validators
        validators: {
            required,
            emailValidator
        },

        // Icons
        icons: {
            mdiTrashCanOutline,
            mdiClose,
            mdiPlus
        },
        }
    },
}
  </script>
  