import store from '@/store'

import {
    mdiAccountOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
  } from '@mdi/js'
import { ref, getCurrentInstance, watch } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions } from '@core/utils'

export default function useSupplierList() {
    const vm = getCurrentInstance().proxy

    const supplierListTable = ref([])
    const supplierLoading = ref(false)
    const supplierData = ref({})
    const totalSupplierListTable = ref(0)
    
    const supplierContactList = ref([])
    const supplierContactLoading = ref(false)
    const supplierContactSearch = ref('')
    
    const countryOptions = ref([])
    const countrySearch = ref('')
    const countryLoading = ref(false)

    const supplierTypeOptions = ref([])
    const supplierTypeLoading = ref(false)

    const searchQuery = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE),
    })


    const suppliersTableColumns = [
        { text: 'NAME', value: 'name' },
        // { text: 'EMAIL', value: 'email' },
        { text: 'PRIMARY CONTACT', value: 'contact_name'},
        { text: 'TYPES', value: 'supplier_types'},
        { text: 'ADDRESS', value: 'address1'},
        { text: 'ZIPCODE', value: 'zip_code'},
        { text: 'CITY', value: 'city'},
        { text: 'COUNTRY', value: 'country'},
        
        
    ]

    const supplierContactTableColumns = [
        { text: 'NAME', value: 'name' },
        { text: 'EMAIL', value: 'email' },
        
    ]

    const resolveSupplierTypeVariant = role => {
        if (role === 'In-Person Facilities') return 'primary'
        if (role === 'Online Panel') return 'warning'
        if (role === 'Associate') return 'success'
        if (role === 'Accountant') return 'info'
        if (role === 'Admin') return 'error'
    
        return 'primary'
      }
    
      const resolveSupplierTypeIcon = role => {
        if (role === 'In-Person Facilities') return mdiAccountOutline
        if (role === 'Online Panel') return mdiCogOutline
        if (role === 'Associate') return mdiDatabaseOutline
        if (role === 'Accountant') return mdiPencilOutline
        if (role === 'Admin') return mdiDnsOutline
    
        return mdiAccountOutline
      }
    
      const initSupplierList = () => {
        supplierListTable.value = []
      }

    // fetch data
    const fetchSuppliers = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        if (searchQuery.value === null) searchQuery.value = '';

        supplierLoading.value = true
        store
        .dispatch('app-supplier/fetchSuppliers',{
            search: searchQuery.value,
            options: JSON.stringify(options.value),
            page: options.value.page,
        })
        .then(response => {
            const { data } = response
            
            supplierListTable.value = data
            totalSupplierListTable.value = supplierListTable.value.length

            // remove loading state
            supplierLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const updateSupplier = (data) => {
        supplierLoading.value = true
        store
        .dispatch('app-supplier/updateSupplier',{
            id:data.id,
            data: data
          })
        .then(response => {
            const { data } = response

            supplierData.value = data

            // remove loading state
            supplierLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const addSupplier = (data) => {
        supplierLoading.value = true
        store
        .dispatch('app-supplier/addSupplier',{
            data: data
          })
        .then(response => {
            const { data } = response

            supplierData.value = data

            // remove loading state
            supplierLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchSupplierTypes = () => {
        supplierTypeLoading.value = true
        store
        .dispatch('app-supplier/fetchSupplierTypes')
        .then(response => {
            const { data } = response

            supplierTypeOptions.value = formatOptions(data)

            // remove loading state
            supplierTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchCountries = async () => {
        countryLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-project/fetchCountries', {})
        .then(response => {
            const { data } = response
            countryOptions.value = formatOptions(data)
            
            // remove loading state
            countryLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchSupplierContacts = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        supplierContactLoading.value = true
        store
        .dispatch('app-supplier/fetchSupplierContacts')
        .then(response => {
            const { data } = response

            supplierContactList.value = data
            // remove loading state
            supplierContactLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const updateSupplierContact = (data) => {
        store
        .dispatch('app-supplier/updateSupplierContact',{
            id:data.id,
            data: data
          })
        .then(response => {
            const { data } = response

        })
        .catch(error => {
            console.log(error)
        })
    }

    const addSupplierContact = (data) => {
        store
        .dispatch('app-supplier/addSupplierContact',{
            data: data
          })
        .then(response => {
            const { data } = response

        })
        .catch(error => {
            console.log(error)
        })
    }

    const submitSearch = (event, ) => {
        if (event) {
          event.preventDefault()
        }
        supplierLoading.value = true
    
        fetchSuppliers()
      }


    watch([supplierData, options], () => {
        supplierLoading.value = true
        fetchSuppliers()
      })


    return {
        supplierListTable,
        supplierLoading,
        supplierData,
        totalSupplierListTable,
        suppliersTableColumns,
        resolveSupplierTypeVariant,
        resolveSupplierTypeIcon,
        countryOptions,
        countryLoading,
        countrySearch,
        supplierTypeOptions,
        supplierTypeLoading,
        submitSearch,
        searchQuery,
        options,
        supplierContactList,
        supplierContactLoading,
        supplierContactSearch,
        supplierContactTableColumns,

        fetchSuppliers,
        fetchSupplierContacts,
        fetchCountries,
        fetchSupplierTypes,

        updateSupplier,
        addSupplier,
        updateSupplierContact,
        addSupplierContact
    }
}