<template>
    <div id="user-list">
        
        <v-card>
            <v-card-title>
                Suppliers list
                <v-spacer></v-spacer>
                <v-form
                    @submit="submitSearch($event)"
                >
                    <v-text-field
                    v-model="searchQuery"
                    placeholder="Search"
                    outlined
                    hide-details
                    dense
                    clearable
                    class="mr-2"
                    :append-icon="icons.mdiSend"
                    @click:append="submitSearch"
                    @click:append-outer="submitSearch"
                    @click:clear="submitSearch"
                    >
                    </v-text-field>
                </v-form>
                <v-btn
                
                color="primary"
                @click="openAddSupplier"
                v-if="$can('add', 'supplier')"
                >
                Add Supplier
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- table -->
                <v-data-table
                    :headers="suppliersTableColumns"
                    :items="supplierListTable"
                    :loading="supplierLoading"
                    :server-items-length="totalSupplierListTable"
                    :options.sync="options"
                    :footer-props="{
                        'items-per-page-options':[options.itemsPerPage],
                        'disable-items-per-page': true,
                    }"
                    @click:row="supplierClick"
                    class="row-pointer"
                >
                    <!-- name -->
                    <template #[`item.name`]="{item}">
                        <div class="d-flex align-left">
                            <div class="d-flex flex-column">
                            {{ item.name }}
                            <!-- <small>@{{ item.office }}</small> -->
                            </div>
                        </div>
                    </template>
                    <!-- name -->
                    <template #[`item.contact_name`]="{item}">
                        <div class="d-flex align-left">
                            <div class="d-flex flex-column">
                            <span>{{ item.contact_name }}</span>
                            <small>{{ item.contact_email }}</small>
                            </div>
                        </div>
                    </template>

                    <!-- types -->
                    <template #[`item.supplier_types`]="{item}">
                        <div class="d-flex align-center mb-1"
                        v-for="supplier_type in item.supplier_types"
                        :key="supplier_type.id">
                            <v-icon
                                size="20"
                                :color="resolveSupplierTypeVariant(supplier_type.name)"
                                class="mr-2"
                            >
                                {{ resolveSupplierTypeIcon(supplier_type.name) }}
                            </v-icon>
                            <span class="text-capitalize">{{ supplier_type.name }}</span>
                        </div>
                    </template>
                    
                    <!-- country -->
                    <template #[`item.country`]="{item}">
                        <div class="d-flex align-left">
                            <div class="d-flex flex-column" v-if="item.country">
                            {{ item.country.name }}
                            </div>
                        </div>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
        
        <supplier-event-handler-drawer-content
            v-model="isSupplierHandlerDrawerActive"
            :supplier="supplier"
            :clear-supplier-data="clearSupplierData"
            @update-supplier="update"
            @add-supplier="add"
            @open-supplier-contact="openSupplierContact"
        ></supplier-event-handler-drawer-content>
        
        <supplier-contact-edit
            :supplierContact="supplierContact"
            :isSupplierContactDialogOpen.sync="isSupplierContactDialogOpen"
            @close-dialog=" isSupplierContactDialogOpen = false; isSupplierHandlerDrawerActive = false"
            @update-contact="updateContact"
            @add-contact="addContact"
        >
        </supplier-contact-edit> 
    </div> 
    
</template>

<script>
// eslint-disable-next-line object-curly-newline

import store from '@/store'
import { onUnmounted, ref, onMounted, watch } from '@vue/composition-api'

import {
  mdiSend,
} from '@mdi/js'

import { stringToHslColor} from '@core/utils'

// sidebar
import SupplierEventHandlerDrawerContent from './SupplierEventHandlerDrawerContent.vue'
import SupplierContactEdit from '../supplier-edit/SupplierContactEdit.vue'

import projectStoreModule from '@/views/apps/project/projectStoreModule'
import supplierStoreModule from '../supplierStoreModule'
import useSupplierList from './useSupplierList'



export default {
    components:{
        SupplierEventHandlerDrawerContent,
        SupplierContactEdit,
    },
  setup() {
    const SUPPLIER_APP_STORE_MODULE_NAME = 'app-supplier'
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule)
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })

    const isSupplierHandlerDrawerActive = ref(false)
    const isSupplierContactHandlerDrawerActive = ref(false)
    const isSupplierContactDialogOpen = ref(false)
    const supplierEvent = {
      id: '',
      name: '',
      start: '',
      end: '',
      timed: false,
      extendedProps: {
        calendar: '',
        guests: [],
        location: '',
        description: '',
      },
    }
    const supplier = ref(JSON.parse(JSON.stringify(supplierEvent)))
    const clearSupplierData = () => {
        supplier.value = JSON.parse(JSON.stringify(supplierEvent))
    }

    

    const supplierClick = (item) => {
        isSupplierHandlerDrawerActive.value = true
        supplier.value = item
    }
    
    const supplierContact = ref({})

    const resetSupplierContact = () => {
        supplierContact.value = {
        first_name: '',
        last_name:'',
        email:''
        }
    }
    const openAddSupplier = () => {
        clearSupplierData()
        resetSupplierContact()
        isSupplierHandlerDrawerActive.value = true
    }

    const openSupplierContact = (contact) => {
        supplierContact.value = JSON.parse(JSON.stringify(contact))
        isSupplierContactDialogOpen.value = true
    }


    const {
        supplierData,
        supplierListTable,
        supplierLoading,
        suppliersTableColumns,
        totalSupplierListTable,
        resolveSupplierTypeVariant,
        resolveSupplierTypeIcon,
        submitSearch,
        searchQuery,
        options,

        fetchSuppliers,
        updateSupplier,
        addSupplier,
        updateSupplierContact,
        addSupplierContact,
    } = useSupplierList()

    const update = async (data) => {
        const localSupplierData = JSON.parse(JSON.stringify(data))
        updateSupplier(localSupplierData)
    }

    const add = async (data) => {
        const localSupplierData = JSON.parse(JSON.stringify(data))
        addSupplier(localSupplierData)
    }

    const updateContact = async (data) => {
        const supplierContactData = JSON.parse(JSON.stringify(data))
        updateSupplierContact(supplierContactData)
        // const tempData = await addSupplier(supplierData)
    }

    const addContact = async (data) => {
        const supplierContactData = JSON.parse(JSON.stringify(data))
        addSupplierContact(supplierContactData)
        
        // const tempData = await addSupplier(supplierData)
    }

    onMounted(() => {
        resetSupplierContact()
        fetchSuppliers()
    })

    watch(
        supplierData,
        () => {
            supplier.value = supplierData.value
    })

    return {
        isSupplierHandlerDrawerActive,
        isSupplierContactHandlerDrawerActive,
        isSupplierContactDialogOpen,
        openSupplierContact,
        openAddSupplier,
        supplierContact,
        supplier,
        clearSupplierData,
        supplierClick,
        totalSupplierListTable,
        supplierListTable,
        supplierLoading,
        suppliersTableColumns,
        resolveSupplierTypeVariant,
        resolveSupplierTypeIcon,
        submitSearch,
        searchQuery,
        options,

        stringToHslColor,

        update,
        add,
        updateContact,
        addContact,

        icons: {
            mdiSend
        }
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>