<template>
  <v-navigation-drawer
    :value="isSupplierHandlerDrawerActive"
    :stateless="keepOpen"
    touchless
    app
    right
    width="420"
    class="overlay"
    temporary
    @input="(val) => {$emit('update:is-supplier-handler-drawer-active', val);}"
  >
  <div class="h-full">

    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">{{ supplierLocal.id ? 'Update' : 'Add' }} Supplier</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('update:is-supplier-handler-drawer-active', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
      <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-calendar-event-handler"
      >
            <v-form
              ref="refSupplierEventHandlerForm"
              class="px-5 pt-3 pb-10"
              @submit.prevent="handleFormSubmit"
            >
              <v-text-field
                v-model="supplierLocal.name"
                label="Name"
                placeholder="BVA Family"
                outlined
                :rules="[validators.required]"
                hide-details="auto"
                dense
                class="mb-6"
              ></v-text-field>

              <v-textarea
                v-model="supplierLocal.address1"
                label="Address"
                placeholder="123 Ave de Grenelle"
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.required]"
              ></v-textarea>

              <v-text-field
                v-model="supplierLocal.zip_code"
                label="ZIP / Postal Code"
                placeholder="75015"
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.required]"
              ></v-text-field>
              <v-text-field
                v-model="supplierLocal.city"
                label="City"
                placeholder="Paris"
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.required]"
              ></v-text-field>
              <v-autocomplete
                v-model="supplierLocal.country"
                :items="countryOptions"
                :loading="countryLoading"
                :search-input.sync="countrySearch"
                item-text="label"
                item-value="value"
                label="Country"
                placeholder="Select..."
                required
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                @change="countrySearch=''"
                :rules="[validators.required]"
              ></v-autocomplete>

              <v-autocomplete
                v-model="supplierLocal.supplier_types"
                :items="supplierTypeOptions"
                :loading="supplierTypeLoading"
                item-text="label"
                item-value="value"
                label="Type"
                placeholder="Select..."
                multiple
                small-chips
                deletable-chips
                required
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.requiredArray]"
              ></v-autocomplete>
              <v-checkbox
                v-model="supplierLocal.internal"
                :value="supplierLocal.internal"
                color="primary"
                label="Internal"
                class="mb-6"
                hide-details
              >
              </v-checkbox>
              <template v-if="supplierLocal.id">
                <v-subheader class="px-0">
                <span>Contacts</span>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  dark
                  x-small
                  color="success"
                >
                <v-icon 
                  dark
                  @click="openSupplierContact()"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                </v-btn>
              </v-subheader>
              <v-data-table
                :headers="supplierContactTableColumns"
                :items="filteredSupplierContactList"
                :loading="supplierContactLoading"
                :items-per-page="5"
                dense
                @click:row="openSupplierContact"
                class="mb-4 row-pointer"
              ></v-data-table>
              </template>
              
              <!-- <v-text-field
                v-model="supplierLocal.contact_name"
                label="Primary Contact"
                placeholder="John Smith"
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.required]"
              ></v-text-field>
              <v-text-field
                v-model="supplierLocal.contact_email"
                label="Primary Email"
                placeholder="john.smith@example.com"
                outlined
                hide-details="auto"
                dense
                class="mb-6"
                :rules="[validators.required, validators.emailValidator]"
              ></v-text-field> -->

              <v-btn
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ supplierLocal.id ? 'Update' : 'Add ' }}
              </v-btn>
              <v-btn
                outlined
                @click="resetEventLocal"
              >
                Reset
              </v-btn>
            </v-form>
          
      </perfect-scrollbar>

  </div>
  </v-navigation-drawer>
  
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { required, urlValidator, emailValidator, requiredArray } from '@core/utils/validation'
import { mdiTrashCanOutline, mdiClose, mdiPlus } from '@mdi/js'
import useSupplierList from './useSupplierList'

export default {
  components: {
    PerfectScrollbar,
  },
  model: {
    prop: 'isSupplierHandlerDrawerActive',
    event: 'update:is-supplier-handler-drawer-active',
  },
  props: {
    isSupplierHandlerDrawerActive: {
      type: Boolean,
      required: true,
    },
    supplier: {
      type: Object,
      required: true,
    },
    clearSupplierData: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refSupplierEventHandlerForm = ref(null)

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    const supplierLocal = ref(JSON.parse(JSON.stringify(props.supplier)))
    const resetEventLocal = () => {
      supplierLocal.value = JSON.parse(JSON.stringify(props.supplier))
      
      if (typeof(supplierLocal.value.country) == 'object') supplierLocal.value.country = props.supplier.country.id
      if (supplierLocal.value.supplier_types && typeof(supplierLocal.value.supplier_types[0]) == 'object') supplierLocal.value.supplier_types = props.supplier.supplier_types.map(type => type.id)

      // Reset Validation
      refSupplierEventHandlerForm.value.resetValidation()
    }

    
    const {
        countryOptions,
        countryLoading,
        countrySearch,
        supplierTypeOptions,
        supplierTypeLoading,
        supplierContactList,
        supplierContactLoading,
        supplierContactTableColumns,
        supplierContactSearch,

        fetchSupplierTypes,
        fetchCountries,
        fetchSupplierContacts
    } = useSupplierList()

    watch(
      () => props.supplier,
      () => {
        resetEventLocal()
        
        fetchSupplierContacts()
      },
      { deep: true },
    )

    // watch(
    //   supplierContactList,
    //   () => {
    //     console.log('update contact or add')
    //   }
    // )

  

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const handleFormSubmit = () => {
      const isFormValid = refSupplierEventHandlerForm.value.validate()

      if (!isFormValid) return

      const supplierData = JSON.parse(JSON.stringify(supplierLocal.value))

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (supplierData.id) emit('update-supplier', supplierData)
      else emit('add-supplier', supplierData)

      // Close sidebar
      // emit('update:is-supplier-handler-drawer-active', false)
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    const openSupplierContact = (contact=null) => {
      if (!contact) contact = {first_name: '', last_name:'', email:'', suppliers: [props.supplier.id]}
      emit('open-supplier-contact', contact)
    }

    onMounted(() => {
      fetchCountries()
      fetchSupplierTypes()
      fetchSupplierContacts()
    })

    const filteredSupplierContactList = computed(() => {
      return supplierContactList.value.filter(item => item.suppliers.includes(props.supplier.id))
    })
    const onClickOutside = () => { return true; }
    const keepOpen = computed(() => {
      return onClickOutside()
    })
    return {
      countryOptions,
      countryLoading,
      countrySearch,
      supplierTypeOptions,
      supplierTypeLoading,
      
      supplierContactLoading,
      supplierContactSearch,
      filteredSupplierContactList,
      supplierContactTableColumns,
      openSupplierContact,

      // Template Refs
      refSupplierEventHandlerForm,

      // Local Event
      supplierLocal,
      resetEventLocal,
      keepOpen,

      // Form
      handleFormSubmit,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Field Validators
      validators: {
        required,
        urlValidator,
        emailValidator,
        requiredArray
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
        mdiPlus
      },
    }
  },
}
</script>

<style lang="scss">
.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
.v-navigation-drawer--overlay {
  
  transform: translateX(100%); 
  z-index: 7;
}
</style>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
